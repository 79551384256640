<template>
  <el-card shadow="never">
    <el-row>
      <el-button type="primary" @click="exportData" :loading="loading" class="btn">导出归还未回绑数据</el-button>
      <MessageComponent ref="searchMessage"/>
    </el-row>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "NotBindReport",
  components: {MessageComponent},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    exportData() {
      this.loading = true;
      this.$axios.get('report/expNotBindReport').then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });

    }
  }
}
</script>

<style scoped>
.btn {
  margin-left: 40px;
}
</style>